<template>
  <el-dialog title="修改" v-model="visibleDialog" width="700px" @close="onClose">
    <el-form :model="form" ref="form" :rules="rules" label-width="140px" :inline="false">
      <el-form-item label>
        <div>
          <el-avatar :size="50" :src="model?.avatar_url" />
        </div> 
      </el-form-item>

      <el-form-item label="名称">
        {{ model?.nick_name }} 
      </el-form-item>
      <el-form-item label="手机号">
        {{ model?.mobile_phone }}
      </el-form-item>
      <el-form-item label="身份证号">
        <el-col :span="14">
          <el-input v-model="form.idcard"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="备注信息">
        <el-col :span="20">
          <el-input v-model="form.remark" :rows="10" type="textarea"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visibleDialog: false,
      saving: false,
      form: {},
      rules: {},
    };
  },
  props: ["model"],
  emits: ["success", "close"],
  watch: {
    model: {
      handler() {
        if (this.model && this.model.id) {
          this.form = {
            id: this.model.id,
            nick_name:this.model.nick_name,
            mobile_phone:this.model.mobile_phone,
            idcard: this.model.idcard,
            remark: this.model.remark,
          };
          this.visibleDialog = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onClose() {
      this.$emit("close");
    },

    /**
     *
     */
    onSubmit() {
      this.saving = true;
      this.$http
        .post("admin/v1/member/edit", this.form)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.visibleDialog = false;
            this.$emit("success");
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped>
</style>